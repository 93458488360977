import React from 'react';
import Estrellas from '../../Commons/Estrellas';
import FotosHelper from '../../../helpers/FotosHelper';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'rebem-classname';
import isMobile from 'ismobilejs';
import ClickAwayListener from '@mui/base/ClickAwayListener';

export default function MapaPie(props){
	if(!props.propiedad || isEmpty(props.propiedad)){
		return null;
	}
	const clickAway = () =>{
		if(props.propiedad.referencia !== ''){
			  props.CerrarMapaPie();
		}
	  }
	const block = 'MapaPie';
	return(
		<ClickAwayListener onClickAway={clickAway}>
			<div data-hover={props.propiedad.referencia} className={stringify({block})} style={{display: props.propiedad.referencia!=null && props.mostrarMapaPie ? 'flex' : 'none'}}>
				<a className={stringify({block, elem:"imagen"})} href={props.propiedad.link} target={isMobile.any ? '_self' : '_blank'}>
					<img src={FotosHelper.imgParaPropiedad(props.propiedad.referencia, props.propiedad.foto_listado, 'para_mapa')} />
				</a>
				<a className={stringify({block, elem:"descripcion"})} href={props.propiedad.link} target={isMobile.any ? '_self' : '_blank'}>
					<div className={stringify({block, elem:"cabecera"})}>
						<div className={stringify({block, elem:"titulo"})}>
							<div className='MapaPie__titulo-primer-renglon MapaPie--ellipsis'>
								{props.propiedad.tipo} para {props.propiedad.capacidad_max} personas 
							</div>
							<div className={stringify({block, elem:"titulo-segundo-renglon"})}>
								<div className="MapaPie__titulo-prop MapaPie--ellipsis">
									{isMobile ? `${props.propiedad.titulo} ${props.propiedad.referencia}` : props.propiedad.titulo} 
								</div>
								{!isMobile ?
									<div className={stringify({block, elem:"titulo-ref"})}>
									({props.propiedad.referencia})
								</div> : null}
							</div>
							<div className='MapaPie__titulo-tercer-renglon MapaPie--ellipsis'>
								{props.propiedad.nombre_localidad}, {props.propiedad.nombre_provincia}
							</div>
						</div>
					</div>
					<div className={stringify({block, elem:"estrellas"})}>
						<Estrellas cantidad={props.propiedad.puntaje} texto={false} evaluaciones={0}/>
					</div>
					{props.propiedad.precio_minimo && props.propiedad.precio_minimo != '0.00'?
						<div className={stringify({block, elem:"precio"})}>
							desde
							<span className={stringify({block, elem:"valor"})}> ${parseInt(props.propiedad.precio_minimo)} </span>
							por noche
						</div>:
						<div className={stringify({block, elem:"precio"})}><span className={stringify({block, elem:"valor"})}>consultar</span></div>
					}
				</a>
				<div className={stringify({block, elem:"cruz", mods:{dual:props.dual}})}>
					<span className="glyphicon glyphicon-remove" onClick={props.CerrarMapaPie}/>
				</div>
			</div>
		</ClickAwayListener>
	);
}